<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
	components: {
		LazyHydrate,
	},
	props: {
		data: Object,
	},
	computed: {
		carouselProps() {
			return this.$b.props({
				feather: {
					t: this.data.items.length > 4 && 100,
				},
				showArrows: true,
				slidesPerPage: 1,
				gutter: 10,
				paginateBySlide: true,
				showDots: {
					m: false,
					t: true,
					d: true,
				},
			})
		},
	},
}
</script>
<template>
	<div v-if="$b.d" :class="$bem('desktop-container')">
		<div class="d-flex items-container px-4">
			<div
				v-for="(item, n) in data.items"
				:key="n"
				class="item d-flex align-center justify-center px-4 py-4 w100"
			>
				<SafeLink :to="item.link">
					<div class="base d-flex align-center justify-center" style="gap: 10px">
						<div style="height: 60px" class="d-flex align-center">
							<div v-if="item.useCustomIcon" v-html="item.customIconHtml" class="pa-2"></div>
							<v-icon v-else size="50px" :color="$vars.primary">{{ item.icon }}</v-icon>
						</div>
						<div class="text-left">
							<div class="font-1 header">{{ item.title }}</div>
							<div>{{ item.subtitle }}</div>
						</div>
					</div>
				</SafeLink>
			</div>
		</div>
	</div>

	<div v-else :class="$bem('slider-with-icons__mobile-container')" class="base">
		<LazyHydrate when-visible>
			<Carousel loop arrowsBgHalfRounded v-bind="carouselProps" :autoplay-delay="data.autoplayDelay">
				<div v-for="(item, i) of data.items" :key="i" :class="$bem('__card')" class="text-center">
					<SafeLink :to="item.link">
						<div
							class="base py-4"
							:style="$b.props({ margin: { t: 'auto' }, 'max-width': { t: '90%' } })"
						>
							<div style="height: 60px">
								<div v-if="item.useCustomIcon" v-html="item.customIconHtml"></div>
								<v-icon v-else size="50px" :color="$vars.primary">{{ item.icon }}</v-icon>
							</div>
							<div class="font-2 header">{{ item.title }}</div>
							<div>{{ item.subtitle }}</div>
						</div>
					</SafeLink>
				</div>
			</Carousel>
		</LazyHydrate>
	</div>
</template>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

@media #{map-get($display-breakpoints, 'sm-and-down')} {
	::v-deep .carousel__arrow {
		box-shadow: none !important;
		background-color: #ffffff00 !important;
		font-size: 24px;
	}
	::v-deep .carousel__arrow-icon {
		font-size: 24px !important;
	}
}

.desktop-container {
	max-width: 1920px;
	margin: auto;
}

.item {
	border-style: solid;
	border-width: 0 1px 0 0;
	border-color: var(--secondary);

	&:last-child {
		border-style: none;
	}
}
</style>

