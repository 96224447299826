<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
	components: {
		LazyHydrate,
	},
	data() {
		return {
			selectedProduct: this.data.products[0],
		}
	},
	props: {
		data: Object,
	},
	mounted() {
		if (this.data.products?.length) {
			this.$eventer().trigger('product-list:view', {
				products: this.data.products,
				listId: `${this.data.collectionId}-${this.data.title}`,
			})
		}
	},
}
</script>

<template>
	<Container class="no-mobile-px">
		<Title
			:title="data.title"
			:titleStyle="data.titleStyle"
			:btn="{ link: data.link, linkText: data.linkText }"
			class="mb-5"
			v-if="data.textEnabled"
		/>

		<div class="secondary" :class="$b.d && $bem('products-board-with-data')">
			<v-row :class="{ 'mx-2 row-border-carousel': $b.mt }">
				<v-col>
					<LazyHydrate when-visible>
						<Carousel
							loop
							arrowsBgHalfRounded
							:dotContainerFullWidthMobile="data.products.length >= 15"
							v-bind="{
								...$b.props({
									slidesPerPage: { m: 2, t: 4, d: 6 },
									showArrows: true,
									center: { m: true },
									showDots: { mt: true },
								}),
								...this.$attrs,
							}"
						>
							<div class="d-flex justify-center" v-for="(product, i) of data.products" :key="i">
								<ProductCard
									small-carousel
									:product="product"
									:list-index="i"
									@card:click="selectedProduct = product"
								/>
							</div>
						</Carousel>
					</LazyHydrate>
				</v-col>
			</v-row>

			<v-row :class="{ 'mx-2 row-border-data-sheet': $b.mt }">
				<v-col>
					<ProductDataSheet :product="selectedProduct" />
				</v-col>
			</v-row>
		</div>
	</Container>
</template>

<style scoped lang="scss">
.products-board-with-data {
	border: thin solid rgb(193, 193, 193);
}

.row-border-carousel {
	border: thin solid rgb(193, 193, 193);
	border-bottom: none;
}

.row-border-data-sheet {
	border: thin solid rgb(193, 193, 193);
	border-top: none;
}
</style>

